<template>
    <b-card title="Service Wise Order">
        <b-row>
            <b-col md="3">
                <b-form-group label="Service">
                    <v-select placeholder="Select Service"></v-select>
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-form-group label="From Date">
                    <flat-pickr v-model="fromDate" class="form-control" name="date" placeholder="Please Enter From Date" />
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-form-group label="To Date">
                    <flat-pickr v-model="toDate" class="form-control" name="date" placeholder="Please Enter To Date" />
                </b-form-group>
            </b-col>
            <b-col md="3">
                <b-button >Filter</b-button>
            </b-col>
        </b-row>
        <hr>
        <div>
            <!-- search input -->
            <div class="custom-search justify-content-end">
                <b-row>
                    <b-col md="8">
                        <!-- <b-button @click="printReport">Print Report</b-button> -->
                    </b-col>
                    <b-col md="4">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

            </div>

            <!-- table -->
            <vue-good-table id="printTable" :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :pagination-options="{
    enabled: false,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">

                    <!-- Column: Name -->
                    <span v-if="props.column.field === 'orderCreateDate'" class="text-nowrap">
                        <span class="text-nowrap">{{ convertTimeStampToDateLocal(props.row.orderCreateDate) }}</span>
                    </span>

                    <!-- Column: Name -->
                    <span v-else-if="props.column.field === 'dueDate'" class="text-nowrap">
                        <span class="text-nowrap">{{ convertTimeStampToDate(props.row.dueDate) }}</span>
                    </span>

                    <span v-else-if="props.column.field === 'customerName'" class="text-nowrap">
                        <span>{{ props.row.customerName + ' (' + props.row.customerMobileNo + ')' }}</span>
                    </span>

                    <span v-else-if="props.column.field === 'orderNo'">
                        <b-link v-b-toggle.sidebar-right-orderDetail @click="getOrderDetail(props.row.id)">
                            {{ '#' + props.row.orderNo }}
                        </b-link>
                    </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'status'">
                        <b-badge :variant="statusVariant(props.row.status)">
                            {{ props.row.status }}
                        </b-badge>
                    </span>


                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                                @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>
</template>
<script>
import { BCard, BRow, BCol, BButton, BFormGroup } from "bootstrap-vue";
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
export default {
    components: {
        BCard, BRow, BCol, BButton, BFormGroup, vSelect, flatPickr, VueGoodTable
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Service Name',
                    field: 'storeName',
                    width: '150px'
                },
                {
                    label: 'Service Type',
                    field: 'customerName',
                    width: '150px'
                },
                {
                    label: 'Amount Without Tax And Discount',
                    field: 'orderNo',
                    width: '90px'
                },
                {
                    label: 'Quantity',
                    field: 'orderCreateDate',
                    width: '145px'
                },
                {
                    label: 'Pieces',
                    field: 'dueDate',
                    width: '145px'
                },
            ],
            rows: [],
            searchTerm: '',
        }
    }
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>